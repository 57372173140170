import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        color: "red",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontFamily: "Poppins" }} variant="h4" mt={2}>
        Page Not Found
      </Typography>
      <Button sx={{}} variant="text" onClick={() => navigate("/dashboard")}>
        Home
      </Button>
    </Box>
  );
};
