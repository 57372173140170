import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

function BankCharges() {
  const [bankCharges, setBankCharges] = useState([]);
  const [userCharges, setUserCharges] = useState({});

  // Fetch data from API
  const fetchBankCharges = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/bankcharges/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBankCharges(response.data);

      // Initialize userCharges state
      const charges = {};

      response.data.forEach((charge) => {
        charges[charge.id] = charge.user_Charges; // Initialize with existing values
      });
      setUserCharges(charges);
    } catch (error) {
      console.error("Error fetching bank charges:", error);
    }
  };

  // Handle user input change
  const handleInputChange = (id, value) => {
    setUserCharges((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // Handle Set button click
  const handleSetCharges = async (id) => {
    const value = userCharges[id];

    // Validation: Check if the input is a valid number (including 0)
    if (value === "" || isNaN(value)) {
      return Swal.fire({
        title: "Invalid Input",
        text: "Please enter a valid number for User Charges.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }

    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api.vlnidhi.com/api/admin/bankCharges/${id}`,
        {
          user_Charges: Number(value), // Convert to a number to ensure correct API format
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Show success alert
      Swal.fire({
        title: "Success",
        text: "User charges updated successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });

      // Optionally refetch data or update state locally
      fetchBankCharges();
    } catch (error) {
      console.error(`Error updating charges for ID ${id}:`, error);
      Swal.fire({
        title: "Error",
        text: "Failed to update charges. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  useEffect(() => {
    fetchBankCharges();
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Bank Charges
      </Typography>

      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 2,
          }}
        >
          Set Charges
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ borderRadius: "20px 20px 0px 0px" }}
        >
          <Table>
            <TableHead sx={{ background: "#413AF1", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Sl No</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Transaction Type</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Transaction Slab</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Your Charges</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>User Charges</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ background: "#F2F6FA" }}>
              {bankCharges.map((charge, index) => (
                <TableRow key={charge.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{charge.transaction_Type}</TableCell>
                  <TableCell>{charge.transaction_Slab}</TableCell>
                  <TableCell>{charge.your_Charges}</TableCell>
                  <TableCell>
                    <TextField
                      value={userCharges[charge.id] || ""}
                      onChange={(e) =>
                        handleInputChange(charge.id, e.target.value)
                      }
                      sx={{
                        backgroundColor: "#FFFFFF",
                        "& .MuiInputBase-root": {
                          height: "38px",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{ background: "#E8C30B" }}
                      onClick={() => handleSetCharges(charge.id)}
                    >
                      Set
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default BankCharges;
