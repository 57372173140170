import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const textFieldStyle = {
  width: { xs: "100%", md: "100%" },
  backgroundColor: "#FFFFFF",
  "& .MuiInputBase-root": {
    height: "48px",
  },
};

const fontStyle = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "14px",
};
function SearchAdvisor() {
  const [advisors, setAdvisors] = useState([]);

  const [branches, setBranches] = useState([]);
  const [positions, setPositions] = useState([]);
  const [filteredAdvisors, setFilteredAdvisors] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [advisorCode, setAdvisorCode] = useState("");
  const [memberCode, setMemberCode] = useState("");
  const [introCode, setIntroCode] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");

  // Function to fetch all advisors (for "Get All" button)
  const fetchAdvisors = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/advisors/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAdvisors(response.data); // Save fetched data to state
    } catch (error) {
      console.error("Error fetching advisors:", error);
    }
  };

  // Fetch data initially (you can adjust if needed)
  useEffect(() => {
    fetchAdvisors(); // Fetch all advisors on component load
  }, []);

  useEffect(() => {
    // Fetch branches from the API
    const fetchBranches = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/getallbid",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBranches(response.data);
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };

    // Fetch positions from the API
    const fetchPositions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/positions/all",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPositions(response.data);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchBranches();
    fetchPositions();
  }, []);

  const handleSearch = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/advisors/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const advisorsData = response.data;
      setAdvisors(advisorsData);

      // Apply filters
      const filtered = advisorsData.filter((advisor) => {
        const matchBranch = selectedBranch
          ? advisor.branch === selectedBranch
          : true;

        const matchPosition = selectedPosition
          ? advisor.position === selectedPosition
          : true;

        const matchAdvisorName = advisorName
          ? advisor.advisorName
              .toLowerCase()
              .includes(advisorName.toLowerCase())
          : true;

        const matchAdvisorCode = advisorCode
          ? advisor.advisorCode === advisorCode
          : true;

        const matchMemberCode = memberCode
          ? advisor.memberCode === memberCode
          : true;

        const matchIntroCode = introCode
          ? advisor.introCode === introCode
          : true;

        const matchDate =
          fromDate && toDate
            ? new Date(advisor.doj) >= new Date(fromDate) &&
              new Date(advisor.doj) <= new Date(toDate)
            : true;

        return (
          matchBranch &&
          matchPosition &&
          matchAdvisorName &&
          matchAdvisorCode &&
          matchMemberCode &&
          matchIntroCode &&
          matchDate
        );
      });

      setFilteredAdvisors(filtered);
    } catch (error) {
      console.error("Error fetching advisors:", error);
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Search Advisor
      </Typography>

      <Paper
        sx={{
          p: { md: 2, xs: 2 },
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Search Box
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: { xs: "16px", md: "18px" },
                  ...fontStyle,
                }}
              >
                Branch
              </Typography>
              <TextField
                select
                variant="outlined"
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                sx={textFieldStyle}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {`${branch.branchName} (${branch.branchCode})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              From Date
            </Typography>
            <TextField
              type="date"
              variant="outlined"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              To Date
            </Typography>
            <TextField
              type="date"
              variant="outlined"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Advisor Name :
            </Typography>
            <TextField
              variant="outlined"
              value={advisorName}
              onChange={(e) => setAdvisorName(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Advisor Code :
            </Typography>
            <TextField
              variant="outlined"
              value={advisorCode}
              onChange={(e) => setAdvisorCode(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Member Code :
            </Typography>
            <TextField
              variant="outlined"
              value={memberCode}
              onChange={(e) => setMemberCode(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Introducer Code :
            </Typography>
            <TextField
              variant="outlined"
              value={introCode}
              onChange={(e) => setIntroCode(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              sx={{
                width: "100%",
                fontSize: { xs: "16px", md: "18px" },
                ...fontStyle,
              }}
            >
              Position :
            </Typography>
            <TextField
              select
              variant="outlined"
              value={selectedPosition}
              onChange={(e) => setSelectedPosition(e.target.value)}
              sx={{
                ...textFieldStyle,
              }}
              InputProps={{
                style: {
                  borderRadius: "10px",
                },
              }}
            >
              {positions.map((position) => (
                <MenuItem
                  key={position.positionId}
                  value={position.positionName}
                >
                  {position.positionNo} - {position.positionName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {/* Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSearch}
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#DFBF41",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#B89B3E" },
              color: "white",
            }}
          >
            Search
          </Button>

          {/* Print Button */}
          {/* <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontFamily: "Poppins",
              background: "#F37D01",
              width: { xs: "50%", md: "157px" },
              height: "48px",
              borderRadius: "10px",
              fontWeight: "bold",
              color: "white",
              ":hover": { background: "#9c5f1e" },
            }}
          >
            Print
          </Button> */}
        </Box>
      </Paper>

      <Paper sx={{ mt: 2, p: 2, borderRadius: "20px" }}>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 2,
            mt: 0,
          }}
        >
          Search List
        </Typography>
        <Box sx={{ overflowX: "auto" }}>
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "20px 20px 0px 0px",
              width: { md: "100%", xs: "296px" },
            }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead sx={{ background: "#413AF1" }}>
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Branch
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    A.Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    A.Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Positon
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    D.O.J
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Intro Code
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Intro Name
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Phone No
                  </TableCell>
                  {/* <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    PAN
                  </TableCell> */}
                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Member Code
                  </TableCell>

                  <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Status
                  </TableCell>
                  {/* <TableCell
                    sx={{ color: "white", fontWeight: 400, fontSize: "13px" }}
                  >
                    Action
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAdvisors.length > 0 ? (
                  filteredAdvisors.map((advisor, index) => (
                    <TableRow
                      key={advisor.slNo}
                      sx={{
                        height: 48,
                        backgroundColor:
                          index % 2 === 0 ? "#F2F6FA" : "#C1E0FB", // Alternate row color
                      }}
                    >
                      <TableCell>{advisor.slNo}</TableCell>
                      <TableCell>{advisor.branch}</TableCell>
                      <TableCell>{advisor.advisorCode}</TableCell>
                      <TableCell>{advisor.advisorName}</TableCell>
                      <TableCell>{advisor.position}</TableCell>
                      <TableCell>
                        {new Date(advisor.doj).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </TableCell>
                      <TableCell>{advisor.introCode}</TableCell>
                      <TableCell>{advisor.introName}</TableCell>
                      <TableCell>{advisor.phoneNo}</TableCell>
                      {/* <TableCell>{advisor.pan}</TableCell> */}
                      <TableCell>{advisor.memberCode}</TableCell>
                      <TableCell>{advisor.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      align="center"
                      sx={{ color: "red" }}
                    >
                      No results found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
}

export default SearchAdvisor;
