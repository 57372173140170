import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

function AddCollector() {
  const [branches, setBranches] = useState([]);
  const [pinCode, setPinCode] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [age, setAge] = useState("");
  const [dob, setDob] = useState("");
  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    sectionHeader: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    label: {
      fontFamily: "Poppins, sans-serif",
      fontSize: { xs: "12px", md: "14px" },
    },
    textField: {
      width: "100%",
      borderColor: "#D9D9D9CC",
      backgroundColor: "#FFFFFF",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
    },
    button: {
      borderRadius: "10px",
      width: { xs: "100%", md: "128px" },
      height: { xs: "38px", md: "48px" },
      color: "white",
      textTransform: "none",
      fontWeight: "bold",
    },
    saveButton: {
      background: "#DFBF41",
      ":hover": {
        background: "#a18e46",
      },
    },
    deleteButton: {
      background: "#d73925",
      ":hover": {
        background: "#a1392c",
      },
    },
    newButton: {
      background: "#05ACD6",
      ":hover": {
        background: "#228097",
      },
    },
  };

  const handleDobChange = (event) => {
    const { value } = event.target;
    const today = new Date();
    const birthDate = new Date(value);
    // Prevent setting a future date
    if (birthDate > today) {
      return;
    }
    // Calculate age
    let calculatedAge = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      calculatedAge--;
    }
    setDob(value);
    setAge(calculatedAge);
  };
  // Setting the max date to today for the date input field
  const today = new Date().toISOString().split("T")[0];

  const handlePinCodeChange = (event) => {
    setPinCode(event.target.value);
  };

  const fetchPinCodeData = async () => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pinCode}`
      );
      const data = response.data;

      if (data[0].Status === "Success") {
        const postOfficeData = data[0].PostOffice[0]; // Taking the first entry as an example
        setDistrict(postOfficeData.District);
        setState(postOfficeData.State);
      } else {
        console.error("Error: Invalid Pin Code");
        setDistrict("");
        setState("");
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error.message);
    }
  };

  // Setting up Axios instance
  const axiosInstance = axios.create({
    baseURL: "https://api.vlnidhi.com/api",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });

  // Fetch branches from API
  const fetchBranches = useCallback(async () => {
    try {
      const response = await axiosInstance.get("/admin/getallbid");
      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error.message);
    }
  }, []);

  // const fetchPositions = useCallback(async () => {
  //   try {
  //     const response = await axiosInstance.get("/admin/positions/all");
  //     setPositions(response.data);
  //   } catch (error) {
  //     console.error("Error fetching positions:", error.message);
  //   }
  // }, []);

  useEffect(() => {
    fetchBranches();
  }, [fetchBranches]);

  return (
    <Box>
      <Typography sx={styles.header}>Add Collector</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Collector Details</Typography>
        <Grid container spacing={2}>
          {/* joiningDate */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Joining Date<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                type="date"
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={joiningDate}
                // onChange={(e) => setJoiningDate(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* branchCode */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Branch Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.id} value={branch.branchCode}>
                    {branch.branchName} - ({branch.branchCode})
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>

          {/* advisorTitle */}
          <Grid item xs={12} md={1}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Title<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={relativeRelation}
                // onChange={(e) => setRelativeRelation(e.target.value)}
              >
                <MenuItem value="MR">Mr.</MenuItem>
                <MenuItem value="MS">MS</MenuItem>
                <MenuItem value="MRS">MRS</MenuItem>
                <MenuItem value="Smt">Smt</MenuItem>
                <MenuItem value="MD">MD</MenuItem>
              </TextField>
            </Box>
          </Grid>

          {/* advisorName */}
          <Grid item xs={12} md={5}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Collector/Advisor Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* gender */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Gender<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={relativeRelation}
                // onChange={(e) => setRelativeRelation(e.target.value)}
              >
                <MenuItem value="MALE">Male</MenuItem>
                <MenuItem value="FEMALE">Female</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </TextField>
            </Box>
          </Grid>

          {/* maritalStatus */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Marital Status<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                <MenuItem value="SINGLE">Single</MenuItem>
                <MenuItem value="MARRIED">Married</MenuItem>
                <MenuItem value="UNMARRIED">Unmarried</MenuItem>
                <MenuItem value="DIVORCED">Divorced</MenuItem>
                <MenuItem value="WIDOWED">Widowed</MenuItem>
                <MenuItem value="SEPARATED">Separated</MenuItem>
              </TextField>
            </Box>
          </Grid>

          {/* address */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Address<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={address}
                // onChange={(e) => setAddress(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* district */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                District<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                value={district}
                sx={styles.textField}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: "bold",
                  },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* state */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                State<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                disabled
                value={state}
                sx={styles.textField}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: "bold",
                  },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* pinCode */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Pin Code<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                value={pinCode}
                onChange={handlePinCodeChange}
                onBlur={fetchPinCodeData}
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* dob */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ ...styles.label }}>
                    Date of Birth<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    value={dob}
                    onChange={handleDobChange}
                    type="date"
                    sx={styles.textField}
                    InputProps={{
                      style: { borderRadius: "10px" },
                    }}
                  ></TextField>
                </Box>
              </Grid>

              {/* age */}
              <Grid item xs={12} md={6}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ ...styles.label }}>
                    Age(in years)
                  </Typography>
                  <TextField
                    value={age}
                    disabled
                    sx={styles.textField}
                    InputProps={{
                      style: { borderRadius: "10px", background: "#eee" },
                    }}
                  ></TextField>
                </Box>
              </Grid>
            </Box>
          </Grid>

          {/* mobileNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Mobile No.<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={mobileNo}
                // onChange={(e) => setMobileNo(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* email */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Email<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={mobileNo}
                // onChange={(e) => setMobileNo(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* education */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Education</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={qualification}
                // onChange={(e) => setQualification(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* occupation */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Occupation</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={qualification}
                // onChange={(e) => setQualification(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* panNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                PAN No.<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={pan}
                // onChange={(e) => setPan(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* aadharNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Aadhar No.<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={pan}
                // onChange={(e) => setPan(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* voterNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Voter No.</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={pan}
                // onChange={(e) => setPan(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* rationNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Ration No.</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={pan}
                // onChange={(e) => setPan(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* dlNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>DL No.</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={pan}
                // onChange={(e) => setPan(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* nomineeName */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Nominee Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={relativeName}
                // onChange={(e) => setRelativeName(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* nomineeRelation */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Nominee Relation<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={relativeRelation}
                // onChange={(e) => setRelativeRelation(e.target.value)}
              >
                <MenuItem value="BROTHER">Brother</MenuItem>
                <MenuItem value="DAUGHTER">Daughter</MenuItem>
                <MenuItem value="FATHER">Father</MenuItem>
                <MenuItem value="HUSBAND">Husband</MenuItem>
                <MenuItem value="MOTHER">Mother</MenuItem>
                <MenuItem value="SISTER">Sister</MenuItem>
                <MenuItem value="SON">Son</MenuItem>
                <MenuItem value="WIFE">Wife</MenuItem>
                <MenuItem value="DAUGHTER_IN_LAW">Daughter in Law</MenuItem>
                <MenuItem value="BROTHER_IN_LAW">Brother in Law</MenuItem>
                <MenuItem value="GRAND_DAUGHTER">Grand Daughter</MenuItem>
                <MenuItem value="GRANDSON">Grand Son</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </TextField>
            </Box>
          </Grid>

          {/* nomineeAge */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Nominee Age<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={relativeAge}
                // onChange={(e) => setRelativeAge(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* nomineeMobileNo */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Nominee Mobile No<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
                // value={mobileNo}
                // onChange={(e) => setMobileNo(e.target.value)}
              ></TextField>
            </Box>
          </Grid>

          {/* smsSend */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>SMS Send Status</Typography>
              <Switch
                sx={{ transform: "scale(1.5)" }}
                //   checked={isAdvisorApproved} // Set switch state based on isAdvisorApproved
                //   onChange={(e) => setIsAdvisorApproved(e.target.checked)} // Update state on toggle
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Introducer Details</Typography>
        <Grid container spacing={2}>
          {/* positionName */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Select Position<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* introducerCode */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Introducer Code<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* introducerName */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Introducer Name<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ ...styles.paper, mt: 2 }}>
        <Typography sx={styles.sectionHeader}>Payment Details</Typography>
        <Grid container spacing={2}>
          {/* fees */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Fees (if any)</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* paymentBy */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>
                Payment By<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          {/* remarks */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Remarks</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button sx={{ ...styles.button, ...styles.saveButton }}>Save</Button>
          <Button sx={{ ...styles.button, ...styles.newButton }}>New</Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default AddCollector;
