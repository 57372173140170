import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";
import vllogo from "../../Assets/logo.png";

function CollectionSheet() {
  const styles = {
    paper: {
      width: "100%",
      p: 2,
      borderRadius: "20px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      px: 3,
    },
    header: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    sectionHeader: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "36px",
      mb: 1,
    },
    label: {
      fontFamily: "Poppins, sans-serif",
      fontSize: { xs: "12px", md: "14px" },
    },
    textField: {
      width: "100%",
      borderColor: "#D9D9D9CC",
      backgroundColor: "#FFFFFF",
      "& .MuiInputBase-root": {
        height: { xs: "40px", md: "48px" },
      },
    },
    button: {
      borderRadius: "10px",
      width: { xs: "100%", md: "128px" },
      height: { xs: "38px", md: "48px" },
      color: "white",
      textTransform: "none",
      fontWeight: "bold",
    },
    saveButton: {
      background: "#DFBF41",
      ":hover": {
        background: "#a18e46",
      },
    },
    newButton: {
      background: "#05ACD6",
      ":hover": {
        background: "#228097",
      },
    },
  };

  return (
    <Box>
      <Typography sx={styles.header}>All Collection Sheet</Typography>
      <Paper sx={styles.paper}>
        <Typography sx={styles.sectionHeader}>Search Box</Typography>
        <Paper
          sx={{
            background: "#D9D9D966",
            display: "flex",
            justifyContent: "space-around",
            flexDirection: { md: "row", xs: "column" },
            mb: 2,
            borderRadius: "10px"
          }}
        >
          <Box>
            <Checkbox /> New
          </Box>
          <Box>
            <Checkbox /> RD Renewal
          </Box>
          <Box>
            <Checkbox /> DRD Renewal
          </Box>
          <Box>
            <Checkbox /> Regular EMI
          </Box>
          <Box>
            <Checkbox /> Irreular EMI
          </Box>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Type</Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Branch</Typography>
              <TextField
                select
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>Code</Typography>
              <TextField
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>From Date</Typography>
              <TextField
                type="date"
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ ...styles.label }}>To Date</Typography>
              <TextField
                type="date"
                sx={styles.textField}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              ></TextField>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button sx={{ ...styles.button, ...styles.saveButton }}>
            Search
          </Button>
        </Box>
      </Paper>

      <Paper sx={{ px: 6, py: 3, mt: 2, borderRadius: "20px" }}>
        {/* Header Section */}
        <Box sx={{ marginBottom: 1 }}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box sx={{ mr: 2 }}>
                <img
                  src={vllogo}
                  alt="vllogo"
                  style={{ height: "80px", width: "80px" }}
                />
              </Box>
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "16px", md: "20px" },
                  mb: 1,
                  fontFamily: "Poppins",
                }}
              >
                VENKETLAXMI URBAN PUNE NIDHI LTD
              </Typography>
              <Typography sx={{ fontFamily: "Poppins" }}>
                Reg. Office: SECOND FLOOR, GOLDEN EMPIRE, BESIDE CROMA, BANER
                ROAD, BANER - MAHARASHTRA - 411045
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mt: 0, mb: 3, borderBottomWidth: 2 }} />
        <Box sx={{textAlign: "center"}}>
            <Typography sx={{textDecoration: "underline", fontWeight: 600, fontSize: "20px", fontFamily: "Poppins"}}>ADVISOR/COLLECTOR/EMPLOYEE COLLECTION REPORT</Typography>
        </Box>
      </Paper>
    </Box>
  );
}

export default CollectionSheet;
