import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";


function FundTransfer() {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState("");
  const [selectedBeneficiaryDetails, setSelectedBeneficiaryDetails] = useState({
    beneficiaryName: "",
    mobieNo: "",
    emailID: "",
    bankAccountNo: "",
    bankIFSC: "",
  });
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [balance, setBalance] = useState("");
  const [transferAmount, setTransferAmount] = useState("");
  const [payMode, setPayMode] = useState("");
  const [payRemarks, setPayRemarks] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/saving-accounts/getAll",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAccounts(response.data);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchAccounts();
  }, []);

  const handleAccountChange = (event) => {
    const selectedId = event.target.value;
    setSelectedAccount(selectedId);
    const selectedAcc = accounts.find((account) => account.id === selectedId);
    if (selectedAcc) {
      setSelectedAccount(selectedAcc);
      setBalance(selectedAcc.openingAmount);
    }
  };

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const response = await axios.get(
          "https://api.vlnidhi.com/api/admin/beneficiaries/all",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBeneficiaries(response.data);
      } catch (error) {
        console.error("Error fetching beneficiaries:", error);
      }
    };

    fetchBeneficiaries();
  }, []);

  const handleBeneficiaryChange = (event) => {
    const selectedId = event.target.value;
    setSelectedBeneficiaryId(selectedId);

    const selectedDetails = beneficiaries.find(
      (beneficiary) => beneficiary.id === parseInt(selectedId)
    );

    setSelectedBeneficiaryDetails(
      selectedDetails || {
        beneficiaryName: "",
        mobieNo: "",
        emailID: "",
        bankAccountNo: "",
        bankIFSC: "",
      }
    );
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };

  const handleTransfer = async () => {
    const payload = {
      payMode,
      savingAccountNo: selectedAccount?.virtualAccNumber,
      bankAccountNo: selectedBeneficiaryDetails.bankAccountNo,
      transferAmount,
      payRemarks,
    };
  
    try {
      const response = await axios.post(
        "https://api.vlnidhi.com/api/admin/fund-transfer/transfer",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      // Show success message using SweetAlert2
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Fund Transfer Successful!",
        confirmButtonColor: "#E8C30B",
      });
  
      // Reset form fields
      setSelectedBeneficiaryId("");
      setSelectedBeneficiaryDetails({
        beneficiaryName: "",
        mobieNo: "",
        emailID: "",
        bankAccountNo: "",
        bankIFSC: "",
      });
      setSelectedAccount("");
      setBalance("");
      setTransferAmount("");
      setPayMode("");
      setPayRemarks("");
    } catch (error) {
      console.error("Error during fund transfer:", error);
  
      // Show error message using SweetAlert2
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Fund Transfer Failed. Please try again.",
        confirmButtonColor: "#E8C30B",
      });
    }
  };
  

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Member Fund Transfer
      </Typography>
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "36px",
              mb: 1,
              mt: 0,
            }}
          >
            Transfer Details
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Select Savings A/C : * 
              </Typography>
              <TextField
                select
                value={selectedAccount?.id || ""}
                onChange={handleAccountChange}
                sx={{ ...textFieldStyle }}
                InputProps={{ style: { borderRadius: "10px" } }}
              >
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {`${account.member?.memberName || "N/A"} (${
                      account.virtualAccNumber
                    })`}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Balance : *
              </Typography>
              <TextField
                disabled
                value={balance}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Pay Mode : *
              </Typography>
              <TextField
                select
                value={payMode}
                onChange={(e) => {
                  setPayMode(e.target.value);
                }}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: { borderRadius: "10px" },
                }}
              >
                <MenuItem value={"IMPS"}>IMPS</MenuItem>
                <MenuItem value={"NEFT"}>NEFT</MenuItem>
                <MenuItem value={"RTGS"}>RTGS</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Trf. Amount : *
              </Typography>
              <TextField
                type="number"
                value={transferAmount}
                onChange={(e) => setTransferAmount(e.target.value)}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Select Beneficiary : * 
              </Typography>
              <TextField
                select
                value={selectedBeneficiaryId}
                onChange={handleBeneficiaryChange}
                sx={{ ...textFieldStyle }}
                InputProps={{ style: { borderRadius: "10px" } }}
              >
                {beneficiaries.map((beneficiary) => (
                  <MenuItem
                    key={beneficiary.id}
                    value={beneficiary.id}
                  >{`${beneficiary.id} - ${beneficiary.beneficiaryName}`}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Transfer To : *
              </Typography>
              <TextField
                disabled
                value={selectedBeneficiaryDetails.beneficiaryName || ""}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Mobile No. : *
              </Typography>
              <TextField
                disabled
                value={selectedBeneficiaryDetails.mobieNo || ""}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Email ID : *
              </Typography>
              <TextField
                disabled
                value={selectedBeneficiaryDetails.emailID || ""}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Bank Account No. : *
              </Typography>
              <TextField
                disabled
                value={selectedBeneficiaryDetails.bankAccountNo || ""}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                IFSC : *
              </Typography>
              <TextField
                disabled
                value={selectedBeneficiaryDetails.bankIFSC || ""}
                sx={{ ...textFieldStyle }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                    background: "#eee",
                    fontWeight: 600,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  ...fontStyle,
                }}
              >
                Pay Remarks : *
              </Typography>
              <TextField
                value={payRemarks}
                onChange={(e) => setPayRemarks(e.target.value)}
                sx={{
                  ...textFieldStyle,
                }}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={handleTransfer}
            sx={{
              background: "#E8C30B",
              borderRadius: "10px",
              textTransform: "none",
              color: "white",
              width: "118px#E8C30B",
              mt: 2.3,
              width: "200px",
              height: "48px",
              fontWeight: "bold",
              fontSize: "16px",
            }}
          >
            Quick Transfer
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default FundTransfer;
