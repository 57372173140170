import React from 'react'
import workinprogress from '../Assets/workinprogress.jpg'

function WorkInProgress() {
  return (
    <div style={{display: "flex", justifyContent: "center"}}>
        <img src={workinprogress} alt="work in progress" />
    </div>
  )
}

export default WorkInProgress