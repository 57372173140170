import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

function AddNewBeneficiary() {
  const [beneficiary, setBeneficiary] = useState({
    beneficiaryName: "",
    mobieNo: "",
    emailID: "",
    bankName: "",
    bankAccountNo: "",
    bankIFSC: "",
  });

  const [beneficiariesList, setBeneficiariesList] = useState([]);

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  const fetchBeneficiaries = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        "https://api.vlnidhi.com/api/admin/beneficiaries/all",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBeneficiariesList(response.data);
    } catch (error) {
      Swal.fire("Error", "Failed to fetch beneficiaries", "error");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBeneficiary((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddBeneficiary = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      Swal.fire("Error", "Token not found. Please log in again.", "error");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.vlnidhi.com/api/admin/beneficiaries/add",
        beneficiary,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        Swal.fire("Success", "Beneficiary added successfully.", "success");
        setBeneficiary({
          beneficiaryName: "",
          mobieNo: "",
          emailID: "",
          bankName: "",
          bankAccountNo: "",
          bankIFSC: "",
        });
        fetchBeneficiaries();
      }
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Something went wrong!",
        "error"
      );
    }
  };

  const textFieldStyle = {
    width: { xs: "100%", md: "100%" },
    borderColor: "#D9D9D9CC",
    backgroundColor: "#FFFFFF",
    "& .MuiInputBase-root": {
      height: { xs: "40px", md: "48px" },
    },
  };

  const fontStyle = {
    fontFamily: "Poppins, sans-serif",
    width: "100%",
    fontSize: { xs: "12px", md: "14px" },
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          mb: 1,
        }}
      >
        Add New Beneficiary
      </Typography>
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
        }}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography sx={fontStyle}>Beneficiary Name *</Typography>
              <TextField
                name="beneficiaryName"
                value={beneficiary.beneficiaryName}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={fontStyle}>Bank Name *</Typography>
              <TextField
                name="bankName"
                value={beneficiary.bankName}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={fontStyle}>Branch *</Typography>
              <TextField
                name="branch"
                // value={beneficiary.bankName}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={fontStyle}>Bank IFSC *</Typography>
              <TextField
                name="bankIFSC"
                value={beneficiary.bankIFSC}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography sx={fontStyle}>Account Number *</Typography>
              <TextField
                name="bankAccountNo"
                value={beneficiary.bankAccountNo}
                onChange={handleInputChange}
                sx={textFieldStyle}
                InputProps={{ style: { borderRadius: "10px" } }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="outlined"
                sx={{
                  color: "#05ACD6",
                  borderColor: "#05ACD6",
                  textTransform: "none",
                  mt: 2.5,
                  width: "140px",
                  height: "48px",
                  borderRadius: "10px",
                }}
              >
                Verfiy Bank
              </Button>
            </Grid>
          </Grid>
          <Box display={"flex"} justifyContent={"flex-end"} gap={2}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "10px",
                textTransform: "none",
                width: "128px",
                mt: 2.3,
                height: "48px",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              Cancel
            </Button>
            <Button
              // onClick={handleAddBeneficiary}
              sx={{
                background: "#05ACD6",
                borderRadius: "10px",
                textTransform: "none",
                color: "white",
                width: "128px",
                mt: 2.3,
                height: "48px",
                fontWeight: "bold",
                fontSize: "16px",
                "&:hover": { background: "#049ac0" },
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Beneficiary List Table */}
      <Paper
        sx={{
          padding: 3,
          borderRadius: "20px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          "@media (max-width: 600px)": {
            padding: "10px",
          },
          mt: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "36px",
            mb: 1,
            mt: 0,
          }}
        >
          Beneficiary List
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "20px 20px 0px 0px" }}
        >
          <Table>
            <TableHead sx={{ background: "#413AF1", color: "white" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Sl No</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Ben ID</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Name</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Mobile</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Email</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>Bank Account</strong>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <strong>IFSC</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {beneficiariesList.map((beneficiary, index) => (
                <TableRow key={beneficiary.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{beneficiary.beneficiaryid}</TableCell>
                  <TableCell>{beneficiary.beneficiaryName}</TableCell>
                  <TableCell>{beneficiary.mobieNo}</TableCell>
                  <TableCell>{beneficiary.emailID}</TableCell>
                  <TableCell>{beneficiary.bankAccountNo}</TableCell>
                  <TableCell>{beneficiary.bankIFSC}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default AddNewBeneficiary;
